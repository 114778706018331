import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EasifyService } from '@services/easify.service';
import { setDashboardLoading } from '@store/loader/loading.actions';
import * as ScheduleActions from '@store/schedule/schedule.actions'; // Import actions
import * as ScheduleSelectors from '@store/schedule/schedule.selectors';
import {
  catchError,
  firstValueFrom,
  from,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import {
  loadTomorrowSuccess,
  refreshSchedule,
  refreshScheduleFailure,
  refreshScheduleSuccess,
} from './schedule.actions';

@Injectable()
export class ScheduleEffects {
  constructor(
    private actions$: Actions,
    private chatService: EasifyService,
    private router: Router,
    private store: Store<any>,
  ) {}

  refreshSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshSchedule), // Listen for the refreshSchedule action
      switchMap((action) => {
        // Dispatch loading actions
        this.store.dispatch(ScheduleActions.loadSchedule());
        this.store.dispatch(ScheduleActions.loadTomorrow());
        this.store.dispatch(setDashboardLoading(true));

        // Call getDaily with the request
        return this.chatService.getDaily(action.request).pipe(
          map((schedule) => {
            // Navigate directly to the dashboard upon success
            this.router.navigate(['/dashboard']);
            // Dispatch success action to update the state
            if (action.request.forTomorrow) {
              return loadTomorrowSuccess({
                tomorrowData: schedule,
              });
            } else {
              return refreshScheduleSuccess({
                scheduleData: schedule,
              });
            }
          }),
          catchError((error) => {
            console.error('Error refreshing schedule:', error);
            this.store.dispatch(setDashboardLoading(false));
            return of(refreshScheduleFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateCustomDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.submitCustomDayRequestFromAPI), // Listen for the submitCustomDayRequest action
      mergeMap(() =>
        from(
          firstValueFrom(
            this.store.select(ScheduleSelectors.selectCustomDayRequest),
          ),
        ).pipe(
          mergeMap((customDayRequest) => {
            if (!customDayRequest) {
              console.log('No custom day request found to submit.');
              return of(
                refreshScheduleFailure({
                  error: 'No custom day request found',
                }),
              );
            }

            // Prepare the request object with defaults
            const request = {
              ...customDayRequest,
              type: customDayRequest.basicInfo?.type || 'full',
            };

            // Call getCustomDay to generate the custom day schedule
            return this.chatService.getCustomDay(request).pipe(
              map((customDaySchedule) => {
                this.router.navigate(['/dashboard']); // Navigate to the dashboard
                if (request.basicInfo?.forTomorrow) {
                  return loadTomorrowSuccess({
                    tomorrowData: customDaySchedule,
                  });
                } else {
                  return refreshScheduleSuccess({
                    scheduleData: customDaySchedule,
                  });
                }
              }),
              catchError((error) => {
                console.error('Error generating custom day:', error);
                return of(refreshScheduleFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );
}
